<template>
  <DescriptorCard
    class_name="PrintInvoice"
    title="Print Invoice"
    v-bind="Object.assign({}, $attrs)"
    v-on="$listeners"
    :_modal_rel_search_components="_modal_rel_search_components"
  ></DescriptorCard>
</template>

<script>
import InvoiceSearch from '../Invoice/InvoiceSearch';

export default {
  components: {
    DescriptorCard: () => import('@/descriptor/coreui/DescriptorCard'),
  },
  props: {},
  data: () => {
    return {};
  },
  computed: {
    _modal_rel_search_components() {
      return {
        PrintInvoice_IncludeInvoices: InvoiceSearch
      };
    },
  },
  created() {},
  methods: {},
};
</script>
