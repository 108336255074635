<template>
  <Search
    v-bind="Object.assign({}, $attrs)"
    v-on="$listeners"
    ref="childRef"
    :_predefined_filters="predefined_filters"
    :_property_cell_variants="property_cell_variants"
  >
    <!-- ref: https://stackoverflow.com/a/50892881/3553367 -->
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </Search>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

import Search from '@/descriptor/coreui/Search'; //cannot use dynamic import or else this.$refs will be undefined

export default {
  components: {
    Search,
  },
  props: {},
  data: () => {
    return {};
  },
  computed: {
    property_cell_variants() {
      return [
        {
          property_key: 'invoice_date',
          cellVariantFunc: params => {
            if (params['invoice_sent'] >= 1) {
              return 'success';
            } else {
              if (this.$d.momentSynced().diff(params['invoice_date'], 'days') <= 1) {
                return 'warning';
              }
            }
            return 'danger';
          },
        },
        {
          property_key: 'total_unpaid',
          cellVariantFunc: params => {
            if (params['total_unpaid'] == 0) {
              return 'success';
            }
            return 'danger';
          },
        },
        {
          property_key: 'invoice_sent',
          cellVariantFunc: params => {
            if (params['invoice_sent'] >= 1) {
              return 'success';
            }
            return 'danger';
          },
        },
        {
          property_key: 'is_cleared',
          cellVariantFunc: params => {
            if (params['is_cleared'] >= 1) {
              return 'success';
            }
            return 'danger';
          },
        },
        {
          property_key: 'is_update_needed',
          cellVariantFunc: params => {
            if (params['is_update_needed'] >= 1) {
              return 'danger';
            }
            return null;
          },
        },
      ];
    },
    predefined_filters() {
      return [
        {
          filter_name: 'Is Overdue',
          filter_description: 'Invoices that are not cleared after 1 week',
          filter_params: {
            filters: [
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'invoice_date',
                max: this.$d.momentFormatDate(this.$d.momentSynced().subtract(1, 'week')),
              },
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'is_cleared',
                equal: false,
              },
            ],
          },
          frontend: {
            // variant: 'success',
            variant: 'danger',
          },
        },
        {
          filter_name: 'Is Sent',
          filter_description: 'Invoices that has no email addresses',
          filter_params: {
            filters: [
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'invoice_sent',
                equal: true,
              },
            ],
          },
          frontend: {
            variant: 'success',
            // variant: 'danger',
          },
        },
        {
          filter_name: 'Is Not Sent',
          filter_description: 'Invoices that has no email addresses',
          filter_params: {
            filters: [
              {
                filter_type: 'property',
                existence: 'absent',
                property_key: 'invoice_sent',
                equal: true,
              },
            ],
          },
          frontend: {
            // variant: 'success',
            variant: 'danger',
          },
        },
        {
          filter_name: 'No Email',
          filter_description: 'Invoices that has no email addresses',
          filter_params: {
            filters: [
              {
                filter_type: 'relationship',
                existence: 'absent',
                from_class_name: 'Invoice',
                relationship_name: 'EmailTo',
              },
            ],
          },
          frontend: {
            // variant: 'success',
            variant: 'danger',
          },
        },
        {
          filter_name: 'Needed Send Email',
          filter_description: 'Invoices that are from today or later, has email addresses, not sent, with total_price more than 0 and not cleared',
          filter_params: {
            filters: [
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'invoice_date',
                max: this.$d.momentFormatDate(this.$d.momentSynced()),
              },
              {
                filter_type: 'relationship',
                existence: 'present',
                from_class_name: 'Invoice',
                relationship_name: 'EmailTo',
              },
              {
                filter_type: 'property',
                existence: 'absent',
                property_key: 'invoice_sent', //IMPORTANT TODO: need to check Invoice last sent datetime to check if resend needed
                equal: true,
              },
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'total_price',
                min: 0.01,
              },
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'is_cleared',
                equal: false,
              },
            ],
          },
          frontend: {
            variant: 'success',
            // variant: 'danger',
          },
        },
        {
          filter_name: 'Needed Print',
          filter_description: 'Invoices that are from today or later, has email addresses, not sent, with total_price more than 0 and not cleared',
          filter_params: {
            filters: [
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'invoice_date',
                max: this.$d.momentFormatDate(this.$d.momentSynced()),
              },
              {
                filter_type: 'relationship',
                existence: 'absent',
                from_class_name: 'Invoice',
                relationship_name: 'EmailTo',
              },
              {
                filter_type: 'relationship',
                existence: 'absent',
                from_class_name: 'PrintInvoice',
                relationship_name: 'IncludeInvoices', //IMPORTANT TODO: need to check Invoice last printed datetime to check if resend needed
              },
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'total_price',
                min: 0.01,
              },
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'is_cleared',
                equal: false,
              },
            ],
          },
          frontend: {
            variant: 'success',
            // variant: 'danger',
          },
        },
        {
          filter_name: 'Is Cleared',
          filter_description: 'Invoices that are cleared',
          filter_params: {
            filters: [
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'is_cleared',
                equal: true,
              },
            ],
          },
          frontend: {
            variant: 'success',
            // variant: 'warning',
            // variant: 'danger',
          },
        },
        {
          filter_name: 'Is Not Cleared',
          filter_description: 'Invoices that are not cleared',
          filter_params: {
            filters: [
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'is_cleared',
                equal: false,
              },
            ],
          },
          frontend: {
            // variant: 'success',
            // variant: 'warning',
            variant: 'danger',
          },
        },
        {
          filter_name: 'Is Not Sent + Credit Term + Needed Print' 
          + ' (' + this.$d.momentFormatDate(this.$d.momentSynced().subtract(1, 'months').startOf('month')) + ' ~ ' +
          this.$d.momentFormatDate(this.$d.momentSynced().subtract(1, 'months').endOf('month')) + ')',
          filter_description: 'Prep Invoices for Previous Month',
          filter_params: {
            filters: [
              //Is Not Sent 
              {
                filter_type: 'property',
                existence: 'absent',
                property_key: 'invoice_sent',
                equal: true,
              }, 

              //Needed Print
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'invoice_date',
                max: this.$d.momentFormatDate(this.$d.momentSynced()),
              },
              {
                filter_type: 'relationship',
                existence: 'absent',
                from_class_name: 'Invoice',
                relationship_name: 'EmailTo',
              },
              {
                filter_type: 'relationship',
                existence: 'absent',
                from_class_name: 'PrintInvoice',
                relationship_name: 'IncludeInvoices', //IMPORTANT TODO: need to check Invoice last printed datetime to check if resend needed
              },
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'total_price',
                min: 0.01,
              },
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'is_cleared',
                equal: false,
              }, 

              //For Credit Term
              {
                filter_type: 'relationship',
                existence: 'present',
                from_class_name: 'Invoice',
                relationship_name: 'UsingPaymentTerms',
                filters: [
                  /*{
                    existence: 'present',
                    filter_type: 'property',
                    property_key: 'name',
                    equal: 'Credit Term',
                  },*/
                  {
                    existence: 'present',
                    filter_type: 'property',
                    property_key: 'id',
                    equal: 2,
                  },
                ],
              }, 

              //For Last Month
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'invoice_date',
                min: this.$d.momentFormatDate(this.$d.momentSynced().subtract(1, 'months').startOf('month')),
              },
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'invoice_date',
                max: this.$d.momentFormatDate(this.$d.momentSynced().subtract(1, 'months').endOf('month')),
              }, 
            ],
          },
          frontend: {
            variant: 'success',
            // variant: 'warning',
          },
        },
      ];
    },
  },
  created() {},
  mounted() {
    Object.keys(this.$refs['childRef'].$options.methods).forEach(method_name => {
      //IMPORTANT NOTE: the vue ref may be undefined initially, make sure this wrapper component is not imported dynamically, no v-if https://stackoverflow.com/a/63233540/3553367
      //TODO: use mixin?
      if (!this.$options.methods[method_name] && !this[method_name]) {
        console.warn('automatically inherit child component method:', method_name);
        this[method_name] = (...args) => {
          console.warn('automatically called child component method:', method_name);
          if (args && args.length) {
            console.warn('arguments:', args);
          }
          this.$refs['childRef'][method_name](...args);
        };
      }
    });
    console.log(this.$options.methods);
  },
  methods: {},
};
</script>
